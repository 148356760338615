enum MultiInputControls {
  Cloudwatch1 = "CloudWatch.1",
  Cloudwatch2 = "CloudWatch.2",
  Cloudwatch3 = "CloudWatch.3",
  Cloudwatch4 = "CloudWatch.4",
  Cloudwatch5 = "CloudWatch.5",
  Cloudwatch6 = "CloudWatch.6",
  Cloudwatch7 = "CloudWatch.7",
  Cloudwatch8 = "CloudWatch.8",
  Cloudwatch9 = "CloudWatch.9",
  Cloudwatch10 = "CloudWatch.10",
  Cloudwatch11 = "CloudWatch.11",
  Cloudwatch12 = "CloudWatch.12",
  Cloudwatch13 = "CloudWatch.13",
  Cloudwatch14 = "CloudWatch.14",
}

export default MultiInputControls;
