import { ChangeEvent, FC, useCallback } from "react";
import MultiInputControls from "../../../models/MultiInputControls";
import { Switch, TableBody, TableRow } from "@mui/material";
import StyledTableCell from "../StyledTableCell";

interface CloudwatchSwitchesProps {
  formikCloudwatchControls: string[];
  setFieldValue: (field: string, controls: string[]) => void;
}

const CloudwatchSwitches: FC<CloudwatchSwitchesProps> = ({
  formikCloudwatchControls,
  setFieldValue,
}) => {
  const controls: Array<string> = Object.values(MultiInputControls);
  const rows = [];

  const handleCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const control: string = e?.target?.name || "";
      const cloudwatchControls = new Set<string>(formikCloudwatchControls);
      if (checked) {
        cloudwatchControls.add(control);
      } else {
        cloudwatchControls.delete(control);
      }
      setFieldValue(
        "cloudwatchControls",
        Array.from(cloudwatchControls.values())
      );
    },
    [formikCloudwatchControls, setFieldValue]
  );

  for (let i = 0; i < controls.length; i += 2) {
    rows.push(
      <TableRow>
        <StyledTableCell size="small" sx={{ p: 0 }}>
          {controls[i]}
        </StyledTableCell>
        <StyledTableCell size="small" sx={{ p: 0 }}>
          <Switch
            checked={formikCloudwatchControls.includes(controls[i])}
            value={controls[i]}
            name={controls[i]}
            size="small"
            onChange={handleCheckboxChange}
            color="success"
          />
        </StyledTableCell>
        <StyledTableCell size="small" sx={{ p: 0 }}>
          {i + 1 < controls.length ? controls[i + 1] : ""}
        </StyledTableCell>
        <StyledTableCell size="small" sx={{ p: 0 }}>
          {i + 1 < controls.length ? (
            <Switch
              checked={formikCloudwatchControls.includes(controls[i + 1])}
              name={controls[i + 1]}
              size="small"
              onChange={handleCheckboxChange}
              color="success"
            />
          ) : (
            ""
          )}
        </StyledTableCell>
      </TableRow>
    );
  }
  return <TableBody>{rows}</TableBody>;
};

export default CloudwatchSwitches;
